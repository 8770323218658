<template>
    <div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<DataTable :value="products" :paginator="true" class="p-datatable-customers" :rows="10" dataKey="product_id" :rowHover="true" 
                        :filters="filters1" :loading="loading1">
					<template #header>
						<div class="table-header">
									List of Reserved Products
									<span class="p-input-icon-left">
										<i class="pi pi-search" />
										<InputText v-model="filters1['global']" placeholder="Global Search" />
									</span>	
						</div>
					</template>
					<template #empty>
						No products found.
					</template>
					<template #loading>
						Loading products data. Please wait.
					</template>
					
                    
                    <Column field="product_name" header="Name" :sortable="true" sortField="product_name">
						<template #body="slotProps" index="product_name">
							{{slotProps.data.product_name}}
						</template>
					</Column>
					<Column header="Product code" :sortable="true" sortField="product_code" filterField="product_code">
						<template #body="slotProps">
							<span style="margin-left: .5em; vertical-align: middle" class="image-text">{{slotProps.data.product_code}}</span>
						</template>
					</Column>
					<Column header="Group" :sortable="true" sortField="product_group" filterField="product_group">
						<template #body="slotProps">
							<span style="margin-left: .5em; vertical-align: middle" class="image-text">{{slotProps.data.product_group}}</span>
						</template>
					</Column>
					<Column field="product_type" header="Type" :sortable="true" sortField="product_type">
						<template #body="slotProps">
							<span>{{slotProps.data.product_type}}</span>
						</template>
					</Column>
					<Column field="hs_code" header="HS Code" :sortable="true" sortField="hs_code">
						<template #body="slotProps">
							<span>{{slotProps.data.hs_code}}</span>
						</template>
					</Column>
					<Column field="origin_product" header="Origin" :sortable="true" sortField="origin_product">
						<template #body="slotProps">
							<span :class="' '">{{slotProps.data.origin_product}}</span>
						</template>
					</Column>
					<Column field="product_width" header="Width" :sortable="true" sortField="product_width">
						<template #body="slotProps">
							<span :class="'' ">{{slotProps.data.product_width}}</span>
						</template>
					</Column>					
					<Column field="product_thickness" header="Thickness" :sortable="true" sortField="product_thickness">
						<template #body="slotProps">
							<span :class="'' ">{{slotProps.data.product_thickness}}</span>
						</template>
					</Column>					
					<Column field="product_length" header="Length" :sortable="true" sortField="product_length">
						<template #body="slotProps">
							<span :class="'' ">{{slotProps.data.product_length}}</span>
						</template>
					</Column>
					<Column field="product_count" header="Count" :sortable="true" sortField="product_count">
						<template #body="slotProps">
							<span :class="'' ">{{slotProps.data.product_count}}</span>
						</template>
					</Column>
					<Column headerStyle="width: 8rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
						<template #body="slotProps">
							<Button type="button" icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="openDeleteModal(slotProps.data)"></Button>
						</template>
					</Column>
				</DataTable>

				<Dialog header="Dialog" v-model:visible="displayDeleteModal" :style="{width: '30vw'}" :modal="true">
					<p>
						Are you sure to delete?
					</p>
					<template #footer>
						<Button label="Yes" @click="deleteProduct" icon="pi pi-check" class="p-button-secondary"/>
						<Button label="No" @click="closeDeleteModal" icon="pi pi-times" class="p-button-secondary"/>
					</template>
				</Dialog>
			</div>
		</div>
	</div>
</template>

<script>

    import ProductService from "../service/ProductsService";
	
	export default {
		data() {
			return {
                products:[],
				selectedProductID: null,
				selectedProduct: {name:'', email:'', address:'', representative:'', phone:''},
				filters1: {},
				filters2: {},
				loading1: true,
				expandedRows: [],
                displayEditModal: false,
				displayNewModal: false,
				displayDeleteModal: false,
				user_admin: false
			}
		},
		productService: null,
        created() {
            this.productService = new ProductService();
		},
		mounted() {
			this.productService.getReserveAll().then(data => {
				if (data.isLogged){
					this.products = data.fetch_data;
					this.loading1 = false;
				}
				else{
					this.$router.push('Login')
				}
			});
		},
		methods: {
            openDeleteModal: function(rowId){
				
				this.selectedProductID = rowId;
				this.displayDeleteModal = true;
			},
			closeDeleteModal(){
				this.displayDeleteModal = false;
			},
			deleteProduct(){
				this.productService.deleteProduct(this.selectedProductID);
			},
		}
	}
</script>

<style scoped lang="scss">
::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
        overflow:hidden;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

.p-progressbar-value.ui-widget-header {
	background: #607d8b;
}

@media (max-width: 640px) {
	.p-progressbar {
		margin-top: .5rem;
	}
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}
</style>
